import { styled } from 'baseui';
import useTranslation from 'utils/react/useTranslation';
import moment from 'moment-timezone';
import { getGeoCodedLocationName } from '../Geocoding/Verification/utils';

import InsignificantText from 'pages/Logistics/Common/InsignificantText';
import PODSection from 'pages/CommuteOffer/Panels/Vehicles/Vehicle/Route/Stop/Info/PODSection';
import OrderStatus from 'components/OrderStatus';
import FTDSection from 'pages/CommuteOffer/Panels/Vehicles/Vehicle/Route/Stop/Info/FTDSection';
import Selectable from 'components/Selectable';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../utils/constants';

import debug from 'utils/debug';

const D2 = debug('p:Logistics:Panels:OrderDetails');

const DetailsContainer = styled('div', {
  padding: '16px',
  margin: '0 20px 20px',
  overflow: 'auto',
  flexGrow: 1,
  fontSize: '14px',
  backgroundColor: '#1c232d',
  borderRadius: '4px',
});

const DetailsSubSection = styled('div', {
  margin: '0 0 40px 0',
});

const DetailsSubSectionHeader = styled('div', ({ $theme }) => ({
  ...$theme.typography.panelTitle700,
}));

const DataWrapper = styled('div', ({ $theme }) => ({
  margin: '8px 0 0 0',
  overflowWrap: 'break-word',
  ...$theme.typography.montserrat,
}));

const useTimeWindows = (timeWindows, timezone) => {
  const values = timeWindows.map(({ open_time_ts, close_time_ts }) => {
    const minRecord = moment(open_time_ts).tz(timezone);
    const maxRecord = moment(close_time_ts).tz(timezone);

    const minRecordDate = minRecord.format(DATE_FORMAT);
    const maxRecordDate = maxRecord.format(DATE_FORMAT);

    const minRecordTime = minRecord.format(DATE_TIME_FORMAT);
    const maxRecordTime = maxRecord.format(
      minRecordDate === maxRecordDate ? TIME_FORMAT : DATE_TIME_FORMAT
    );

    return `${minRecordTime} - ${maxRecordTime}`;
  });

  return values;
};

const useOrderTimewindows = (minTime, maxTime, timeWindows, timezone) => {
  const objectTimeWindows = [
    {
      open_time_ts: minTime,
      close_time_ts: maxTime,
      close_time_ts_dynamic: maxTime,
    },
  ];

  const extraTimeWindows = !Array.isArray(timeWindows) ? [] : timeWindows;

  const orderTimeWindows = [...objectTimeWindows, ...extraTimeWindows].filter(
    ({ open_time_ts, close_time_ts, close_time_ts_dynamic }) =>
      !!open_time_ts && !!close_time_ts && !!close_time_ts_dynamic
  );

  return useTimeWindows(orderTimeWindows, timezone);
};

const OrderDetails = ({ orderDetails, currentProject, style }) => {
  const { t, i18n } = useTranslation();

  D2.S.INFO('orderDetails', { orderDetails, currentProject });

  const timezone = currentProject.get('timezone');

  const {
    min_pickup_time,
    max_pickup_time,
    pickup_time_windows,
    min_dropoff_time,
    max_dropoff_time,
    dropoff_time_windows,
    $routeStops,
  } = orderDetails;

  const pickupTimeWindows = useOrderTimewindows(
    min_pickup_time,
    max_pickup_time,
    pickup_time_windows,
    timezone
  );
  const dropoffTimeWindows = useOrderTimewindows(
    min_dropoff_time,
    max_dropoff_time,
    dropoff_time_windows,
    timezone
  );

  const route = $routeStops?.find((routeData) => {
    return routeData.stop_id === orderDetails?.scheduled_dropoff_stop_id;
  });

  const nodeData = route?.bookings?.find((booking) => {
    return orderDetails.uid === booking.id;
  });

  const showBookingStatus = /(completed|fail_to_deliver)/i.test(
    orderDetails?.state
  );

  return (
    <DetailsContainer style={style} data-testid='Logistics-OrderDetails'>
      <Selectable>
        <DetailsSubSection>
          <DetailsSubSectionHeader>
            {t('p.Orders.order.deliveryStatus')}
          </DetailsSubSectionHeader>
          <DataWrapper>
            {showBookingStatus && (
              <div style={{ margin: '4px 0' }}>
                <OrderStatus status={orderDetails.state} />
              </div>
            )}
            {window.GEODISC_POD_SECTION_ENABLED &&
              orderDetails?.state === 'completed' && (
                <PODSection node={nodeData.$node} />
              )}
            {window.GEODISC_POD_SECTION_ENABLED &&
              orderDetails?.state === 'fail_to_deliver' && (
                <FTDSection node={nodeData.$node} />
              )}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.timingInfo.pickedUpAt')}
            </InsignificantText>
            <br />
            {orderDetails.actual_pickup_time
              ? moment(orderDetails.actual_pickup_time)
                  .tz(timezone)
                  .format(DATE_TIME_FORMAT)
              : t('notAvailable')}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.timingInfo.arrivedAt')}
            </InsignificantText>
            <br />
            {orderDetails.actual_dropoff_time
              ? moment(orderDetails.actual_dropoff_time)
                  .tz(timezone)
                  .format(DATE_TIME_FORMAT)
              : t('notAvailable')}
          </DataWrapper>
        </DetailsSubSection>
        <DetailsSubSection>
          <DetailsSubSectionHeader>
            {t('p.Orders.order.recipientInfo')}
          </DetailsSubSectionHeader>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.personalInfo.name')}
            </InsignificantText>
            <br />
            {[
              orderDetails.data?.customer_name,
              orderDetails.data?.customer_name2,
            ]
              .filter(text => !!text)
              .join(' ')
              .trim() || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.personalInfo.number')}
            </InsignificantText>
            <br />
            {orderDetails.data?.customer_phone ||
              orderDetails.data?.phone ||
              '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.dropoffAddress')}
            </InsignificantText>
            <br />
            {getGeoCodedLocationName({
              type: 'dropoff',
              addressData: orderDetails,
            })}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.unitNumber')}
            </InsignificantText>
            <br />
            {orderDetails?.data?.raw_order_record?.dropoff_unit_number || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.dropOffTime')}
            </InsignificantText>
            <br />
            {dropoffTimeWindows.map(value => (
              <div key={value}>{value}</div>
            ))}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.timingInfo.dropoffScheduledTime')}
            </InsignificantText>
            <br />
            {orderDetails?.assigned_vehicle_id
              ? moment(orderDetails.$scheduled_dropoff_time)
                  .tz(timezone)
                  .format(DATE_TIME_FORMAT)
              : '-'}
          </DataWrapper>
        </DetailsSubSection>
        <DetailsSubSection>
          <DetailsSubSectionHeader>
            {t('p.Orders.order.senderInfo')}
          </DetailsSubSectionHeader>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.personalInfo.name')}
            </InsignificantText>
            <br />
            {[
              orderDetails.data?.pickup_customer_name,
              orderDetails.data?.pickup_customer_name2,
            ]
              .filter(text => !!text)
              .join(' ')
              .trim() || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.personalInfo.number')}
            </InsignificantText>
            <br />
            {orderDetails.data?.pickup_customer_phone || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.personalInfo.pickupAddress')}
            </InsignificantText>
            <br />
            {getGeoCodedLocationName({
              type: 'pickup',
              addressData: orderDetails,
            })}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.unitNumber')}
            </InsignificantText>
            <br />
            {orderDetails?.data?.raw_order_record?.pickup_unit_number || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.pickupTime')}
            </InsignificantText>
            <br />
            {pickupTimeWindows.map(value => (
              <div key={value}>{value}</div>
            ))}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.timingInfo.pickupScheduledTime')}
            </InsignificantText>
            <br />
            {orderDetails?.assigned_vehicle_id
              ? moment(orderDetails.$scheduled_pickup_time)
                  .tz(timezone)
                  .format(DATE_TIME_FORMAT)
              : '-'}
          </DataWrapper>
        </DetailsSubSection>
        <DetailsSubSection>
          <DetailsSubSectionHeader>
            {t('p.Orders.order.details')}
          </DetailsSubSectionHeader>
          <DataWrapper>
            <InsignificantText>{t('p.Orders.order.idLabel')}</InsignificantText>
            <br />
            {orderDetails?.data?.external_id || '-'}
          </DataWrapper>
          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.shippingInfo.demandLoad')}
            </InsignificantText>
            <br />
            {Object.keys(orderDetails?.demand || {}).length
              ? Object.keys(orderDetails?.demand || {}).map((key) => {
                  return (
                    <div key={key}>
                      <span>{orderDetails?.demand[key]}</span>{' '}
                      <span style={{ textTransform: 'capitalize' }}>{key}</span>
                    </div>
                  );
                })
              : '-'}
          </DataWrapper>

          <DataWrapper>
            <InsignificantText>{t('p.Orders.order.remark')}</InsignificantText>
            <br />
            {orderDetails?.data?.raw_order_record?.remarks || '-'}
          </DataWrapper>

          <DataWrapper>
            <InsignificantText>{t('p.Orders.order.labels')}</InsignificantText>
            <br />
            {orderDetails?.data?.raw_order_record?.vehicle_labels
              ? Object.values(
                  orderDetails?.data?.raw_order_record?.vehicle_labels || {}
                )[0].join(', ')
              : '-'}
          </DataWrapper>

          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.labels.operator')}
            </InsignificantText>
            <br />
            {orderDetails?.data?.raw_order_record?.vehicle_labels
              ? Object.keys(
                  orderDetails?.data?.raw_order_record?.vehicle_labels || {}
                ).join('')
              : '-'}
          </DataWrapper>

          <DataWrapper>
            <InsignificantText>
              {t('p.Orders.order.vehicleUID')}
            </InsignificantText>
            <br />
            {orderDetails?.assigned_vehicle_id || '-'}
          </DataWrapper>

          {orderDetails?.uid && (
            <DataWrapper>
              <InsignificantText>
                {t('p.Orders.order.bookingUID')}
              </InsignificantText>
              <br />
              {orderDetails?.uid}
            </DataWrapper>
          )}
        </DetailsSubSection>
      </Selectable>
    </DetailsContainer>
  );
};

export default OrderDetails;
