import React from 'utils/react';
import { useState } from 'react';
import useTranslation from 'utils/react/useTranslation';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { v4 as uuidv4 } from 'uuid';

import debug from 'utils/debug';
import FormField from 'components/Forms/FormField';
import { Divider } from 'components/Forms/FormStyles';
import CustomButton from 'components/Forms/Button';
import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useForm } from 'react-hook-form';
import Select from 'components/Forms/Select';
import { styled } from 'baseui';
import Accordion from 'components/Accordion';
import { defaultVehicleRoutingEngineJSON } from 'utils/CommuteOffer/defaults';
import formatForThisDate, {
  removeTimezome,
} from 'utils/moment/formatForThisDate';
import TimePickerField from 'components/Forms/TimePickerField';
import regexValidation from 'utils/forms';
import { filteredGeofences, getReadableTime, vehicleModelsList } from './utils';
import RadioField from 'components/Forms/RadioWithButtons';
import truckIcon from 'assets/white_truck.svg';
import characteristicsIcon from 'assets/characteristics.svg';
import labelIcon from 'assets/label.svg';
import toasterNegative from 'utils/react/toasterNegative';
import CheckboxField from 'components/Forms/Checkbox';

const D2 = debug('c:ModalWindows:VehicleEditorV2');
const FieldWrapper = styled('div', () => {
  return {
    marginTop: '45px',
  };
});

const FieldSection = styled('div', ({ $theme }) => {
  return {
    margin: '46px 0 16px 0',
    fontWeight: '600',
    fontSize: '16px',
    ...$theme.typography.montserrat,
  };
});

const FieldDescription = styled('div', ({ $theme }) => {
  return {
    margin: '0 0 0 0',
    color: $theme.colors.inputDescription,
    ...$theme.typography.panelTitle500,
  };
});

const DisplayText = styled('p', ({ $theme }) => {
  return {
    fontWeight: '500',
    fontSize: '12px',
    color: 'rgb(255 255 255 / 70%)',
    margin: '0 20px',
    overflowWrap: 'break-word',
    ...$theme.typography.montserrat,
  };
});

const DriverExistText = styled('div', ({ $theme }) => {
  return {
    fontWeight: '500',
    fontSize: '14px',
    color: $theme.colors.warning,
    marginTop: '20px',
    overflowWrap: 'break-word',
    ...$theme.typography.montserrat,
  };
});

const DisplayTitle = styled('h3', ({ $theme, icon }) => {
  return {
    fontWeight: '500',
    fontSize: '12px',
    color: '#ffffff',
    marginBottom: '5px',
    ':before': {
      content: `url(${icon})`,
      width: '20px',
      height: '20px',
      display: 'inline-block',
    },
    ...$theme.typography.montserrat,
  };
});

const defaultValues = {
  vehicleName: '',
  vehicleType: [],
  startTime: new Date(),
  endTime: new Date(),
  geofence: [],
  startPointLatitude: 0,
  startPointLongitude: 0,
  startPointName: '',
  endPointLatitude: 0,
  endPointLongitude: 0,
  endPointName: '',
  driver: [],
};

const VehicleEditorV2 = (props) => {
  D2.S.INFO('VehicleEditorV2', props);
  const {
    drivers,
    geofences,
    vehicleTypes,
    simulation,
    vehicles,
    fleetAddVehicle,
    updateVehicle,
  } = props;

  const timezone = global.GEODISC_TIMEZONE;
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    reset,
    watch,
    trigger,
  } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues,
  });
  const values = getValues();

  const [selectedVehicleData, setSelectedVehicleData] = useState(undefined);
  const [vehicleId, setVehicleId] = useState(0);
  const [isClone, setIsClone] = useState(false);
  const [vehicleAgentId, setVehicleAgentId] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [accordionState, setAccordionState] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState({});
  const [saveDataCallback, setSaveDataCallback] = useState(() => {});
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState();

  const [css, theme] = useStyletron('');
  const watchOperationType = watch('operationType', 'single');

  const vehicleTypeOptions = [...vehicleModelsList(vehicleTypes)];
  const formattedGeofences = filteredGeofences(geofences);
  const driverOptions = drivers.map(driver => ({
    value: driver.resource_uri,
    label: `${driver.username || '-'} (${driver.first_name || ''} ${
      driver.last_name || ''
    })`,
  }));
  const driverExistOnVehicle =
    values?.driver &&
    values?.driver[0] &&
    vehicles.find(
      vehicle =>
        vehicle.driver === values?.driver[0]?.value && vehicle.id !== vehicleId
    );

  useEffect(() => {
    window.openVehicleEditorV2 = (opts) => {
      const options = opts || {};
      const selectedVehicle = options?.data;
      setSelectedVehicleData(selectedVehicle);
      setSaveDataCallback((prevState) => {
        return options?.setData;
      });
      const isEditVehicle = !!selectedVehicle && !options?.isClone;
      const isCloneVehicle = !!selectedVehicle && options?.isClone;

      setIsClone(isCloneVehicle);
      setIsOpen(true);
      setSize(options?.size);
      setSelectedDate(moment(simulation?.start_time).tz(timezone).format());
      if (isEditVehicle) {
        setVehicleId(selectedVehicle?.id);
        setVehicleAgentId(selectedVehicle?.agent_id);
        setVehicleName(selectedVehicle?.name);
        const selectedGeofences = (selectedVehicle?.geofences || [])?.map(
          (key) => {
            const geofenceData = formattedGeofences.find((data) => {
              return data.value === key;
            });

            return geofenceData;
          }
        );
        const selectedType = vehicleTypeOptions.find(
          option => option.label === selectedVehicle?.typeLabel
        );
        const selectedTypes = selectedType ? [selectedType] : [];

        const startPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === 1
        );
        const endPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === -1
        );
        const isMultiDay =
          !selectedVehicle?.startTimeStamp || !selectedVehicle?.endTimeStamp;
        const selectedDriver = driverOptions.find(
          option => option.value === selectedVehicle.driver
        );

        const earliestBreakStartTime = getReadableTime({
          time: selectedVehicle?.earliestBreakStartTime,
          timezone,
        });

        const latestBreakEndTime = getReadableTime({
          time: selectedVehicle?.latestBreakEndTime,
          timezone,
        });
        reset({
          vehicleName: selectedVehicle?.name,
          vehicleType: selectedTypes,
          startTime:
            getReadableTime({
              time: selectedVehicle?.startTimeStamp,
              timezone,
            }) || moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime:
            getReadableTime({
              time: selectedVehicle?.endTimeStamp,
              timezone,
            }) || moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
          geofence: selectedGeofences,
          startPointResourceUri: startPoint?.resource_uri,
          startPointLatitude: startPoint?.lat?.toString(),
          startPointLongitude: startPoint?.lon?.toString(),
          startPointName: startPoint?.location_name || '',
          endPointResourceUri: endPoint?.resource_uri,
          endPointLatitude: endPoint?.lat?.toString(),
          endPointLongitude: endPoint?.lon?.toString(),
          endPointName: endPoint?.location_name || '',
          operationType: isMultiDay ? 'multi' : 'single',
          driver: selectedDriver ? [selectedDriver] : [],
          showDriverBreak:
            earliestBreakStartTime ||
            latestBreakEndTime ||
            selectedVehicle?.breakDuration,
          earliestBreakStartTime: earliestBreakStartTime || null,
          latestBreakEndTime: latestBreakEndTime || null,
          //RG shows breakDuration in minutes
          breakDuration: selectedVehicle?.breakDuration / 60,
        });
      } else if (isCloneVehicle) {
        setVehicleId(0);
        setVehicleAgentId(uuidv4());
        const selectedGeofences = (selectedVehicle?.geofences || [])?.map(
          (key) => {
            return { value: key };
          }
        );

        const selectedType = vehicleTypeOptions.find(
          option => option.label === selectedVehicle?.typeLabel
        );
        const selectedTypes = selectedType ? [selectedType] : [];
        const startPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === 1
        );
        const endPoint = selectedVehicle?.route?.find(
          stop => stop?.partial_route_index === -1
        );
        const isMultiDay =
          !selectedVehicle?.startTimeStamp || !selectedVehicle?.endTimeStamp;
        const selectedDriver = driverOptions.find(
          option => option.value === selectedVehicle.driver
        );
        const earliestBreakStartTime = getReadableTime({
          time: selectedVehicle?.earliestBreakStartTime,
          timezone,
        });

        const latestBreakEndTime = getReadableTime({
          time: selectedVehicle?.latestBreakEndTime,
          timezone,
        });

        reset({
          vehicleType: selectedTypes,
          startTime:
            getReadableTime({
              time: selectedVehicle?.startTimeStamp,
              timezone,
            }) || moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime:
            getReadableTime({
              time: selectedVehicle?.endTimeStamp,
              timezone,
            }) || moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
          geofence: selectedGeofences,
          startPointLatitude: startPoint?.lat?.toString(),
          startPointLongitude: startPoint?.lon?.toString(),
          startPointName: startPoint?.location_name || '',
          endPointLatitude: endPoint?.lat?.toString(),
          endPointLongitude: endPoint?.lon?.toString(),
          endPointName: endPoint?.location_name || '',
          operationType: isMultiDay ? 'multi' : 'single',
          driver: selectedDriver ? [selectedDriver] : [],
          showDriverBreak:
            earliestBreakStartTime ||
            latestBreakEndTime ||
            selectedVehicle?.breakDuration,
          earliestBreakStartTime: earliestBreakStartTime || null,
          latestBreakEndTime: latestBreakEndTime || null,
          //RG shows breakDuration in minutes
          breakDuration: selectedVehicle?.breakDuration / 60,
        });
      } else {
        setVehicleId(0);
        setVehicleAgentId(uuidv4());
        reset({
          ...defaultValues,
          startTime: moment.tz('08:00', 'LT', moment.tz.guess()).toDate(),
          endTime: moment.tz('20:00', 'LT', moment.tz.guess()).toDate(),
        });
      }
    };
    window.closeVehicleEditorV2 = () => {
      setIsOpen(false);
      setAccordionState(false);
    };
  }, [t, simulation, reset, timezone]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (!value?.vehicleType?.[0]) {
        setVehicleTypeData({});
        return;
      }

      setVehicleTypeData(
        typeof value?.vehicleType[0]?.value === 'string'
          ? JSON.parse(value?.vehicleType[0]?.value)
          : value?.vehicleType?.[0]
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function onClose({ closeSource }) {
    if (closeSource !== 'backdrop') {
      global.closeVehicleEditorV2();
    }
  }

  const onSubmit = async (formValues) => {
    const selectedVehicleModel =
      formValues?.vehicleType[0]?.value &&
      typeof formValues?.vehicleType[0]?.value === 'string'
        ? JSON.parse(formValues?.vehicleType[0]?.value)
        : {};

    const selectedVehicleAgent = {
      ...selectedVehicleModel,
      id: undefined,
    };

    const defaultVehicleRoutingEngine = {
      ...JSON.parse(defaultVehicleRoutingEngineJSON),
      routing_engine_name: 'osrme',
      road_network: 'van',
      continue_straight: true,
    };
    const isMultiDay = formValues.operationType === 'multi';

    const vehicle = {
      lon: 0,
      lat: 0,
      speed: 0,
      ...(selectedVehicleAgent || {}),
      id: vehicleId,
      agent_id: vehicleAgentId,
      service_number: formValues?.vehicleName,
      labels: selectedVehicleModel?.vehicle_labels,
      start_time: isMultiDay
        ? null
        : formatForThisDate(
            selectedDate,
            `${formValues?.startTime.getHours()}:${formValues?.startTime.getMinutes()}`,
            timezone
          ),
      end_time: isMultiDay
        ? null
        : formatForThisDate(
            selectedDate,
            `${formValues?.endTime.getHours()}:${formValues?.endTime.getMinutes()}`,
            timezone
          ),
      geofence_ids: (formValues?.geofence || []).map(
        geofence => geofence.value
      ),
      routing_engine_settings: {
        ...(defaultVehicleRoutingEngine || {}),
        ...(selectedVehicleModel?.routing_engine_settings || {}),
        vehicle_model: selectedVehicleModel?.id,
      },
      efficiency: selectedVehicleAgent?.efficiency || {},
      driver:
        formValues.driver && formValues.driver[0]
          ? formValues.driver[0].value
          : null,
      dynamic_break_start_time: formValues?.showDriverBreak
        ? formatForThisDate(
            selectedDate,
            `${formValues?.earliestBreakStartTime.getHours()}:${formValues?.earliestBreakStartTime.getMinutes()}`,
            timezone
          )
        : null,
      dynamic_break_end_time: formValues?.showDriverBreak
        ? formatForThisDate(
            selectedDate,
            `${formValues?.latestBreakEndTime.getHours()}:${formValues?.latestBreakEndTime.getMinutes()}`,
            timezone
          )
        : null,
      // dynamic_break_duration should be in seconds
      dynamic_break_duration: formValues?.showDriverBreak
        ? formValues?.breakDuration * 60
        : null,
    };
    D2.S.INFO('vehicle', {
      vehicle,
      defaultVehicleRoutingEngine,
      selectedVehicleModel,
      selectedVehicleAgent,
    });

    const serviceNumbers = vehicles
      .filter(v => v.id !== vehicle.id)
      .map(vehicle => vehicle.service_number.toLowerCase());

    const normalizedServiceNumber = vehicle.service_number.trim().toLowerCase();

    if (serviceNumbers.includes(normalizedServiceNumber)) {
      toasterNegative(
        t('p.fleet.vehicle.add.fail', { name: vehicle.service_number })
      );
      return;
    }

    const simulationStartTS = moment(simulation?.start_time)
      .tz(timezone)
      .format();
    const simulationEndTS = moment(simulation?.end_time).tz(timezone).format();
    // add MAX_SIMULATION_DURATION = 1 month to buffer for when simulation.end_time changed
    const maxSimulationEndTS = moment(simulation?.end_time)
      .tz(timezone)
      .add(1, 'month')
      .format();
    const fleetData = {
      data: { vehicles, sim_id: simulation?.id },
      values: vehicle,
    };
    if (formValues.startPointLatitude && formValues.startPointLongitude) {
      fleetData.startPoint = {
        resource_uri: formValues.startPointResourceUri,
        lat: Number(formValues.startPointLatitude) || 0,
        lon: Number(formValues.startPointLongitude) || 0,
        uid: uuidv4(),
        scheduled_ts: simulationStartTS,
        open_time_ts: simulationStartTS,
        close_time_ts: simulationEndTS,
        close_time_ts_dynamic: simulationEndTS,
        location_name:
          formValues.startPointName ||
          t('p.fleet.editor.advancedSettings.startPoint'),
      };
    } else if (formValues.startPointResourceUri) {
      fleetData.startPoint = {
        delete_resource_uri: formValues.startPointResourceUri,
      };
    }
    if (formValues.endPointLatitude && formValues.endPointLongitude) {
      fleetData.endPoint = {
        resource_uri: formValues.endPointResourceUri,
        lat: Number(formValues.endPointLatitude) || 0,
        lon: Number(formValues.endPointLongitude) || 0,
        uid: uuidv4(),
        scheduled_ts: maxSimulationEndTS,
        open_time_ts: simulationStartTS,
        close_time_ts: maxSimulationEndTS,
        close_time_ts_dynamic: maxSimulationEndTS,
        location_name:
          formValues.endPointName ||
          t('p.fleet.editor.advancedSettings.endPoint'),
      };
    } else if (formValues.endPointResourceUri) {
      fleetData.endPoint = {
        delete_resource_uri: formValues.endPointResourceUri,
      };
    }
    if (vehicleId) {
      if (saveDataCallback) {
        saveDataCallback((prevData) => {
          return prevData.map((item) => {
            // previous the condition is wrong and return object cannot be used, have to remap
            if (item.agent_id === fleetData.values.agent_id) {
              let updatedVehicleData = { ...selectedVehicleData };
              updatedVehicleData.name = fleetData.values.service_number;
              updatedVehicleData.breakDuration =
                fleetData.values.dynamic_break_duration;
              updatedVehicleData.driver = fleetData.values.driver;
              updatedVehicleData.earliestBreakStartTime = removeTimezome(
                fleetData.values.dynamic_break_start_time
              );
              updatedVehicleData.latestBreakEndTime = removeTimezome(
                fleetData.values.dynamic_break_end_time
              );
              updatedVehicleData.geofences = fleetData.values.geofence_ids;
              updatedVehicleData.lat = fleetData.values.lat;
              updatedVehicleData.lon = fleetData.values.lon;
              updatedVehicleData.startTime = `${formValues?.startTime.getHours()}:${formValues?.startTime.getMinutes()}`;
              updatedVehicleData.startTimeStamp = removeTimezome(
                fleetData.values.start_time
              );
              updatedVehicleData.endTime = `${formValues?.endTime.getHours()}:${formValues?.endTime.getMinutes()}`;
              updatedVehicleData.endTimeStamp = removeTimezome(
                fleetData.values.end_time
              );
              updatedVehicleData.typeLabel = selectedVehicleModel.id;
              return updatedVehicleData;
            }

            return item;
          });
        }, 'editor on submit');
      }
      updateVehicle(fleetData);
    } else {
      fleetAddVehicle(fleetData);
    }

    global.closeVehicleEditorV2();
  };

  // This will re-adjust the scroll heigh upon changing the advance settings accordion has been expanded
  useEffect(() => {
    const objDiv = document.getElementById('modalBody');
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  }, [accordionState]);

  return (
    <>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 22,
            },
          },
          Dialog: {
            style: {
              backgroundColor: theme?.colors?.containerBackground,
              height: '97vh',
              overflow: 'hidden',
            },
          },
          Close: {
            style: {
              color: theme?.colors?.white,
            },
          },
        }}
        size={size}
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat !important',
            fontSize: '24px',
            fontWeight: 700,
            color: `${theme?.colors?.white} !important`,
            marginTop: '10px !important',
          })}
        >
          {vehicleId
            ? t('p.fleet.editor.edit.title', { vehicleName: vehicleName })
            : isClone
            ? t('p.fleet.editor.clone.title')
            : t('p.fleet.editor.title')}
        </ModalHeader>
        <ModalBody
          id='modalBody'
          className={css({
            fontFamily: 'Montserrat',
            color: `${theme?.colors?.white} !important`,
            fontSize: '14px',
            fontWeight: 500,
            overflow: 'scroll !important',
            height: 'calc(100% - 150px)',
            paddingBottom: '10px',
          })}
        >
          <div className='form-group' />
          <form onSubmit={handleSubmit(onSubmit)} id='add-vehicle-form'>
            <div className='form-group'>
              <div className={css({ marginTop: '40px' })}>
                <FieldWrapper>
                  <FormField
                    type='string'
                    label={t('p.fleet.editor.vehicleName')}
                    name='vehicleName'
                    placeholder={t('p.fleet.editor.vehicleName')}
                    register={register('vehicleName', {
                      required: t('u.validation.required'),
                      maxLength: {
                        value: 50,
                        message: t('u.validation.maxLength', { count: '50' }),
                      },
                    })}
                    errors={errors.vehicleName}
                    width='288px'
                  />
                </FieldWrapper>
                <FieldWrapper data-testid='Vehicle Type'>
                  <Select
                    type='string'
                    label={t('p.fleet.editor.vehicleType')}
                    name='vehicleType'
                    errors={errors.vehicleType}
                    width='288px'
                    addLabel={t('p.fleet.editor.vehicleType.addLabel')}
                    options={vehicleTypeOptions}
                    control={control}
                    addAction={() => {
                      global.openVehicleTypeEditor();
                    }}
                    rules={{ required: t('u.validation.required') }}
                    labelKey='label'
                    valueKey='value'
                  />
                </FieldWrapper>

                {vehicleTypeData?.id && (
                  <FieldWrapper>
                    <DisplayText
                      className={css({
                        marginLeft: '0 !important',
                        marginBottom: '18px !important',
                      })}
                      data-testid='VehicleEditor - Specification'
                    >
                      {t('p.fleet.editor.specifications.title', {
                        typeName: vehicleTypeData?.id,
                      })}
                    </DisplayText>
                    <div
                      className={css({
                        display: 'flex',
                        backgroundColor: 'rgba(151, 160, 192, 0.04)',
                        border: '1px solid rgba(151, 160, 192, 0.3)',
                        borderRadius: '4px',
                        padding: '16px 20px',
                      })}
                    >
                      <div
                        className={css({
                          width: '50%',
                        })}
                      >
                        <DisplayTitle
                          icon={characteristicsIcon}
                          data-testid='VehicleEditor - Vehicle characteristics'
                        >
                          {t(
                            'p.fleet.editor.specifications.characteristics.title'
                          )}
                        </DisplayTitle>
                        <DisplayText>
                          {vehicleTypeData?.characteristics?.height
                            ? t(
                                'p.fleet.editor.specifications.characteristics.height',
                                {
                                  value:
                                    vehicleTypeData?.characteristics?.height,
                                }
                              )
                            : t('p.none')}
                        </DisplayText>
                        <DisplayText>
                          {vehicleTypeData?.characteristics?.weight
                            ? t(
                                'p.fleet.editor.specifications.characteristics.weight',
                                {
                                  value:
                                    vehicleTypeData?.characteristics?.weight,
                                }
                              )
                            : t('p.none')}
                        </DisplayText>
                        <DisplayText>
                          {vehicleTypeData?.characteristics?.manpower
                            ? t(
                                'p.fleet.editor.specifications.characteristics.manpower',
                                {
                                  value:
                                    vehicleTypeData?.characteristics?.manpower,
                                }
                              )
                            : t('p.none')}
                        </DisplayText>
                      </div>
                      <div
                        className={css({
                          width: '50%',
                        })}
                      >
                        <div
                          className={css({
                            width: '50%',
                          })}
                        >
                          <div>
                            <DisplayTitle
                              icon={labelIcon}
                              data-testid='VehicleEditor - Vehicle label'
                            >
                              {t('p.fleet.editor.specifications.label.title')}
                            </DisplayTitle>
                            <DisplayText>
                              {vehicleTypeData.vehicle_labels?.length
                                ? vehicleTypeData.vehicle_labels.join(', ')
                                : t('p.none')}
                            </DisplayText>
                          </div>
                          <div>
                            <DisplayTitle
                              icon={truckIcon}
                              data-testid='VehicleEditor - Max load'
                            >
                              {t('p.fleet.editor.specifications.load.title')}
                            </DisplayTitle>
                            {Object.keys(vehicleTypeData?.capacity || {})?.map(
                              (key) => {
                                return (
                                  <DisplayText
                                    key={key}
                                  >{`${key} ${vehicleTypeData?.capacity?.[key]}`}</DisplayText>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FieldWrapper>
                )}
                <FieldWrapper data-testid='Vehicle driver'>
                  <Select
                    type='string'
                    label={t('p.fleet.editor.driver')}
                    placeholder={t('p.fleet.editor.driverPlaceholder')}
                    name='driver'
                    errors={errors.driver}
                    options={driverOptions}
                    control={control}
                    labelKey='label'
                    valueKey='value'
                  />
                </FieldWrapper>
                {driverExistOnVehicle && (
                  <DriverExistText>
                    {t('p.fleet.editor.driverExist', {
                      vehicleName: driverExistOnVehicle.service_number,
                    })}
                  </DriverExistText>
                )}
                <Divider className={css({ marginTop: '45px' })} />
                <FieldWrapper
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 0,
                  })}
                >
                  <FieldSection
                    className={css({
                      marginBottom: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                    })}
                  >
                    {t('p.fleet.editor.breakTime')}
                  </FieldSection>
                  <div
                    className={css({
                      display: 'flex',
                      flexDirection: 'row',
                    })}
                  >
                    <FieldDescription className={{ marginRight: '24px' }}>
                      {t('p.fleet.editor.breakTime.description')}
                    </FieldDescription>
                    <div
                      className={css({
                        width: 'auto',
                        position: 'relative',
                        top: '-30px',
                      })}
                    >
                      <CheckboxField
                        type='toggle'
                        name={'showDriverBreak'}
                        control={control}
                      />
                    </div>
                  </div>
                  {values.showDriverBreak && (
                    <FieldWrapper
                      className={css({ display: 'flex', flexDirection: 'row' })}
                    >
                      <div
                        data-testid='Start Time'
                        className={css({ marginRight: 0 })}
                      >
                        <TimePickerField
                          name='earliestBreakStartTime'
                          label={t(
                            'p.fleet.editor.breakTime.earliestBreakStartTime'
                          )}
                          width='184px'
                          errors={errors.earliestBreakStartTime}
                          creatable
                          control={control}
                          maxTime={values?.latestBreakEndTime}
                          afterOnChange={() => {
                            if (getValues('latestBreakEndTime')) {
                              trigger('latestBreakEndTime');
                            }
                            if (getValues('breakDuration')) {
                              trigger('breakDuration');
                            }
                          }}
                          nullable
                          rules={{
                            validate: {
                              required: (value) => {
                                if (!getValues('earliestBreakStartTime')) {
                                  return t(
                                    'p.fleet.editor.breakTime.validation.missing'
                                  );
                                } else if (
                                  moment(getValues('latestBreakEndTime'))
                                    .tz(timezone)
                                    .isSameOrBefore(moment(value).tz(timezone))
                                ) {
                                  return t(
                                    'p.fleet.editor.breakTime.earliestBreakStartTime.higher'
                                  );
                                }
                              },
                            },
                          }}
                        />
                      </div>
                      <div
                        data-testid='End Time'
                        className={css({
                          marginRight: 'auto',
                          marginLeft: '20px',
                        })}
                      >
                        <TimePickerField
                          name='latestBreakEndTime'
                          label={t(
                            'p.fleet.editor.breakTime.latestBreakEndTime'
                          )}
                          width='184px'
                          errors={errors.latestBreakEndTime}
                          creatable
                          control={control}
                          minTime={values?.earliestBreakStartTime}
                          afterOnChange={() => {
                            if (getValues('earliestBreakStartTime')) {
                              trigger('earliestBreakStartTime');
                            }
                            if (getValues('breakDuration')) {
                              trigger('breakDuration');
                            }
                          }}
                          nullable
                          rules={{
                            validate: {
                              required: (value) => {
                                if (!getValues('latestBreakEndTime')) {
                                  return t(
                                    'p.fleet.editor.breakTime.validation.missing'
                                  );
                                } else if (
                                  moment(getValues('earliestBreakStartTime'))
                                    .tz(timezone)
                                    .isSameOrAfter(moment(value).tz(timezone))
                                ) {
                                  return t(
                                    'p.fleet.editor.breakTime.latestBreakEndTime.validation.less'
                                  );
                                }
                              },
                            },
                          }}
                        />
                      </div>
                      <div
                        data-testid='End Time'
                        className={css({
                          marginRight: 'auto',
                          marginLeft: '20px',
                        })}
                      >
                        <FormField
                          type='string'
                          label={t('p.fleet.editor.breakTime.breakDuration')}
                          name='breakDuration'
                          placeholder={t(
                            'p.fleet.editor.breakTime.breakDuration.placeholder'
                          )}
                          register={register('breakDuration', {
                            pattern: {
                              value: regexValidation?.positiveInteger,
                              message: t('u.validation.isPositiveNumber'),
                            },
                            validate: {
                              required: (value) => {
                                const duration = moment.duration(
                                  moment(getValues('latestBreakEndTime'))
                                    .tz(timezone)
                                    .diff(
                                      moment(
                                        getValues('earliestBreakStartTime')
                                      ).tz(timezone)
                                    )
                                );
                                const minutes = duration.asMinutes();
                                if (
                                  getValues('latestBreakEndTime') &&
                                  getValues('earliestBreakStartTime') &&
                                  minutes < Number(value)
                                ) {
                                  return t(
                                    'p.fleet.editor.breakTime.breakDuration.validation.exceed'
                                  );
                                } else if (!getValues('breakDuration')) {
                                  return t(
                                    'p.fleet.editor.breakTime.validation.missing'
                                  );
                                }
                              },
                            },
                          })}
                          errors={errors.breakDuration}
                          width='184px'
                          unit='mins'
                          rules={{}}
                        />
                      </div>
                    </FieldWrapper>
                  )}
                </FieldWrapper>

                <Divider className={css({ marginTop: '45px' })} />

                <FieldWrapper
                  className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 0,
                  })}
                >
                  <div className={css({ marginRight: 0 })}>
                    <FieldSection>
                      {t('p.fleet.editor.advancedSettings.operationType')}
                    </FieldSection>
                    <RadioField
                      color='white'
                      labelMargin='8px 14px'
                      name='operationType'
                      radioButtonSize='18px'
                      options={[
                        {
                          value: 'multi',
                          label: t(
                            'p.fleet.editor.advancedSettings.operationType.multi'
                          ),
                        },
                        {
                          value: 'single',
                          label: t(
                            'p.fleet.editor.advancedSettings.operationType.single'
                          ),
                        },
                      ]}
                      selected='single'
                      control={control}
                    />
                  </div>
                </FieldWrapper>
                {watchOperationType !== 'multi' && (
                  <FieldWrapper
                    className={css({ display: 'flex', flexDirection: 'row' })}
                  >
                    <div
                      data-testid='Start Time'
                      className={css({ marginRight: 0 })}
                    >
                      <TimePickerField
                        name='startTime'
                        label={t('p.fleet.editor.startTime')}
                        width='184px'
                        maxTime={values?.endTime}
                        errors={errors.startTime}
                        control={control}
                        rules={{}}
                      />
                    </div>
                    <div
                      data-testid='End Time'
                      className={css({
                        marginRight: 'auto',
                        marginLeft: '20px',
                      })}
                    >
                      <TimePickerField
                        name='endTime'
                        label={t('p.fleet.editor.endTime')}
                        width='184px'
                        minTime={values?.startTime}
                        errors={errors.endTime}
                        control={control}
                      />
                    </div>
                  </FieldWrapper>
                )}

                <Divider className={css({ marginTop: '45px' })} />

                <div>
                  <Accordion
                    title={t('p.fleet.editor.advancedSettings')}
                    accordionState={accordionState}
                    setAccordionState={setAccordionState}
                  >
                    <>
                      <FieldSection>
                        {t('p.fleet.editor.advancedSettings.startLocation')}
                      </FieldSection>
                      <FieldWrapper
                        className={css({
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 0,
                        })}
                      >
                        <div className={css({ marginRight: 0 })}>
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.latitude')}
                            name='startPointLatitude'
                            value={values?.startPointLatitude}
                            register={register('startPointLatitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('startPointLongitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.latitudeRegex,
                                message: t('u.validation.isLatitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.startPointLatitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          className={css({
                            marginRight: 'auto',
                            marginLeft: '20px',
                          })}
                        >
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.longitude')}
                            name='startPointLongitude'
                            value={values?.startPointLongitude}
                            register={register('startPointLongitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('startPointLatitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.longitudeRegex,
                                message: t('u.validation.isLongitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.startPointLongitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          className={css({
                            marginRight: 'auto',
                            marginLeft: '20px',
                          })}
                        >
                          <FormField
                            type='string'
                            label={t(
                              'p.fleet.editor.advancedSettings.startPointName'
                            )}
                            name='startPointName'
                            placeholder={t(
                              'p.fleet.editor.advancedSettings.startPointName'
                            )}
                            register={register('startPointName', {
                              maxLength: {
                                value: 50,
                                message: t('u.validation.maxLength', {
                                  count: '50',
                                }),
                              },
                            })}
                            errors={errors.startPointName}
                            width='184px'
                          />
                        </div>
                      </FieldWrapper>
                      <FieldSection>
                        {t('p.fleet.editor.advancedSettings.endLocation')}
                      </FieldSection>
                      <FieldWrapper
                        className={css({
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 0,
                        })}
                      >
                        <div className={css({ marginRight: 0 })}>
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.latitude')}
                            name='endPointLatitude'
                            value={values?.endPointLatitude}
                            register={register('endPointLatitude', {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    getValues('endPointLongitude')
                                  ) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.latitudeRegex,
                                message: t('u.validation.isLatitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.endPointLatitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          className={css({
                            marginRight: 'auto',
                            marginLeft: '20px',
                          })}
                        >
                          <FormField
                            type='string'
                            label={t('p.fleet.editor.longitude')}
                            name='endPointLongitude'
                            value={values?.endPointLongitude}
                            register={register('endPointLongitude', {
                              validate: {
                                required: (value) => {
                                  if (!value && getValues('endPointLatitude')) {
                                    return t('u.validation.requiredValue');
                                  }
                                  return true;
                                },
                              },
                              pattern: {
                                value: regexValidation?.longitudeRegex,
                                message: t('u.validation.isLongitude'),
                              },
                              maxLength: {
                                value: 20,
                                message: t('u.validation.maxLength', {
                                  count: 20,
                                }),
                              },
                            })}
                            errors={errors.endPointLongitude}
                            placeholder='0.000'
                            width='184px'
                          />
                        </div>
                        <div
                          className={css({
                            marginRight: 'auto',
                            marginLeft: '20px',
                          })}
                        >
                          <FormField
                            type='string'
                            label={t(
                              'p.fleet.editor.advancedSettings.endPointName'
                            )}
                            name='endPointName'
                            placeholder={t(
                              'p.fleet.editor.advancedSettings.endPointName'
                            )}
                            register={register('endPointName', {
                              maxLength: {
                                value: 50,
                                message: t('u.validation.maxLength', {
                                  count: '50',
                                }),
                              },
                            })}
                            errors={errors.endPointName}
                            width='184px'
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <Select
                          type='string'
                          label={t('p.fleet.editor.geofence')}
                          name='geofence'
                          control={control}
                          // Not sure why got undefined inside the geofence but filter away undefined to prevent crashing
                          value={
                            values?.geofence !== undefined &&
                            values?.geofence !== null &&
                            values?.geofence?.constructor === Array
                              ? values?.geofence?.filter((item) => {
                                  return item !== undefined;
                                })
                              : values?.geofence
                          }
                          register={register('geofence')}
                          errors={errors?.geofence}
                          width='288px'
                          labelKey='label'
                          valueKey='value'
                          placeholder={t('p.fleet.editor.geofence.placeholder')}
                          multi={true}
                          options={[...(filteredGeofences(geofences) || [])]}
                        />
                      </FieldWrapper>
                    </>
                  </Accordion>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter
          className={css({
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            background: theme?.colors?.containerBackground,
            margin: '0 !important',
          })}
        >
          {[
            {
              text: t('c.messages.Cancel'),
              fill: false,
              action: () => {
                global.closeVehicleEditorV2();
              },
            },
            {
              text: t('c.messages.Save'),
              type: 'submit',
              formId: 'add-vehicle-form',
              fill: true,
            },
          ]?.map((data, i) => {
            return (
              <CustomButton
                key={i}
                type={data?.type}
                form={data?.formId}
                $filled={data?.fill}
                onClick={data?.action}
              >
                {data?.text}
              </CustomButton>
            );
          })}
        </ModalFooter>
      </Modal>
    </>
  );
};
window.openVehicleEditorV2 = () => {};
window.closeVehicleEditorV2 = () => {};

export default VehicleEditorV2;
