import React, { useState, useEffect, useMemo, useRef } from 'react';
import { styled } from 'baseui';
import Immutable from 'immutable';
import { useDropzone } from 'react-dropzone';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGISTICS_ROUTE } from 'utils/constants';
import { getGeoViewportFromGeometry } from 'utils/maps';
import { sleep } from 'utils/async';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { Notification, KIND } from 'baseui/notification';
import Alert from 'baseui/icon/alert';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import styledComponents from 'styled-components';
import LoadingWithSpinner from 'components/LoadingWithSpinner';

import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import Overflow from 'baseui/icon/overflow';
import MapGL, { Image, Layer, Source } from '@urbica/react-map-gl';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { LabelSmall } from 'baseui/typography';

import exportLogisticsOrders from 'utils/Logistics/OrdersTable/Export/exportLogisticsOrders';

import {
  waitSimulationProcessor,
  waitSimulationUncalculatedBookings,
  getBookingsForSimulation,
} from 'api/simulations';
import { optimizeLogisticsOrders } from 'api/logistics';

import { Delete } from 'baseui/icon';
import { map as mapConfig } from 'config';
import AddressVerification from '../Geocoding/Verification';
import Badge from './Badge';
import assignedPinIcon from 'assets/assigned-pin.png';
import unassignedPinIcon from 'assets/unassigned-pin.png';
import searchIcon from 'assets/search.svg';
import { getGeoCodedLocationName } from '../Geocoding/Verification/utils';
import OrderDetails from '../OrderDetails';

import InsignificantText from 'pages/Logistics/Common/InsignificantText';

import { getImportErrorList } from './csvValidations';
import { csvUpload } from './csvUpload';
import LanguageControl from '../../../components/Map/LanguageControl';
import { getVehicleStats } from '../../../utils';
import toasterNegative from 'utils/react/toasterNegative';

import debug from 'utils/debug';

const D2 = debug('p:Logistics:Panels:Orders');

const Container = styled('div', {
  position: 'fixed',
  top: '8px',
  left: '116px',
  bottom: '58px',
  zIndex: 1,
  display: 'flex',
  pointerEvents: 'none',
});

const Panel = styled('div', ({ hidden }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  backgroundColor: '#080d14bf',
  backgroundImage:
    'linear-gradient(180deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px), linear-gradient(0deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px)',
  backdropFilter: 'blur(8px)',
  width: '300px',
  color: '#fff',
  transition: hidden ? 'opacity 0.3s ease-out' : 'opacity 0.5s ease-out',
  opacity: hidden ? 0 : 1,
  pointerEvents: hidden ? 'none' : 'auto',
}));

const Heading = styled('h1', ({ $theme }) => ({
  fontSize: '16px',
  padding: '20px',
  margin: 0,
  ...$theme.typography.montserrat,
}));

const Dropzone = styled('div', {
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'stretch',
  position: 'relative',
  overflow: 'auto',
});

const DropzoneDragActive = styled('div', {
  position: 'absolute',
  top: 0,
  left: '20px',
  right: '20px',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #ffffff',
  borderRadius: '12px',
  backdropFilter: 'blur(8px)',
  padding: '20px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '17px',
});

const DropzoneDragError = styled('div', {
  position: 'absolute',
  top: 0,
  left: '20px',
  right: '20px',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #ff0000',
  borderRadius: '12px',
  backdropFilter: 'blur(8px)',
  padding: '20px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '17px',
});

const OrdersList = styled('ul', {
  listStyle: 'none',
  padding: '0 20px',
  margin: 0,
  overflow: 'auto',
  flexGrow: 1,
});

const OrderItem = styled('li', ({ selected }) => ({
  listStyle: 'none',
  padding: '16px',
  backgroundColor: selected ? '#232c35' : '#1c232d',
  borderRadius: '4px',
  marginBottom: '12px',
  fontSize: '14px',
  cursor: 'pointer',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: selected ? '#737780' : 'transparent',
}));

const Toolbar = styled('div', {
  padding: '0 20px 10px',
});

const Footer = styled('div', {
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
});

const Button = styled('button', ({ disabled }) => ({
  background: disabled ? '#424963' : '#1235B2',
  borderRadius: '4px',
  fontSize: '14px',
  color: disabled ? '#97a0c0' : '#fff',
  border: 0,
  margin: 0,
  padding: '12px',
  appearance: 'none',
  fontFamily: 'inherit',
  width: '100%',
  cursor: disabled ? '' : 'pointer',
}));

const FilterInput = styledComponents.input`
  width: 100%;
  border: 0;
  outline: none;
  color: #fff;
  background: transparent;
  margin-left: 10.5px;
  font-size: 14px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.placeholderColor};
  }

  :-ms-input-placeholder {
    color: ${props => props.placeholderColor};
  }
`;

const OverflowButton = styled('button', {
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  border: 0,
  padding: 0,
  margin: 0,
  appearance: 'none',
  verticalAlign: '32px',
  backgroundColor: 'transparent',
  color: '#fff',
});

const OrderItemTitle = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

const InputWrapper = styled('div', ({ $theme }) => ({
  padding: '8px 16px 8px 18.5px',
  backgroundColor: $theme.colors.menuBackground,
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
}));

const PADDING = {
  top: 100,
  bottom: 100,
  left: 730,
  right: 100,
};

const OrdersPanel = ({
  viewport,
  onViewportChange,
  orders,
  currentProject,
  currentProjectConfig,
  changeBookingsFilter,
  projects,
  data,
  simulation,
  commuteOfferRequestUpdate,
  pageAddress,
  numberOfUnResolvedAddresses,
  unfilteredOrders,
  finishOfferLoading,
  validOrders,
  removeOrder,
  hasOnlineVehicles,
  invalidateAllBookings,
  finishInvalidateAllBookings,
  removeAllOrdersResult,
  removeAllOrdersLoading,
  isReadOnly,
  updateSimulation,
  vehicles,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const timezone = currentProject.get('timezone');
  const [orderDetails, setOrderDetails] = useState(false);
  const [
    isAddressVerificationAvailableAfterImport,
    setIsAddressVerificationAvailableAfterImport,
  ] = useState(false);
  const [css, theme] = useStyletron('');

  const mapRef = useRef();
  const removeAllOrdersLoadingRef = useRef(false);

  const ordersDisplay =
    simulation?.data.logistics_api_settings.orders_display || 'dropoff_only';

  const vehicleStats = useMemo(() => {
    return getVehicleStats(vehicles, simulation?.data?.logistics_api_settings);
  }, [vehicles, simulation]);

  const location = useLocation();
  const [isOrderPage, setIsOrderPage] = useState(true);
  const [backListener, setBackListener] = useState(undefined);

  const onViewportChangeHandler = React.useCallback(
    (viewport) => {
      D2.S.INFO('onViewportChangeHandler', viewport);
      onViewportChange(viewport);
    },
    [onViewportChange, D2]
  );

  useEffect(() => {
    D2.S.INFO('VIEWPORT', viewport);
  }, [viewport]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    setIsOrderPage(!params.has('page'));
  }, [location]);

  const unregisterBackListener = (origin) => {
    setBackListener((prevListener) => {
      if (prevListener !== undefined) {
        prevListener();
      }
      return undefined;
    });
  };

  useEffect(() => {
    if (!isOrderPage && backListener !== undefined) {
      unregisterBackListener('useEffect backListener');
    }
  }, [backListener]);

  useEffect(() => {
    if (isOrderPage) {
      let listener = history.listen((location, action) => {
        if (action === 'POP') {
          const params = new URLSearchParams(window.location.search);
          if (params.get('page') === 'verification') {
            global.openFullScreen({
              modalContent: <AddressVerification />,
            });
          }
        }
      });
      setBackListener(prevState => listener);
    } else {
      if (backListener !== undefined) {
        unregisterBackListener('use effect isOrderPage');
      }
    }
  }, [isOrderPage]);

  useEffect(() => {
    if (!removeAllOrdersLoading && removeAllOrdersLoadingRef.current) {
      if (removeAllOrdersResult && removeAllOrdersResult.error) {
        global.openWarningMessage({
          title: t('p.Orders.removeAllOrders.error.title'),
          message: t('p.Orders.removeAllOrders.error.message'),
          buttons: [
            {
              text: t('c.messages.OK'),
              fill: true,
            },
          ],
        });
      } else {
        toaster.info(<>{t('p.Orders.removeAllOrders.success.message')}</>, {
          autoHideDuration: 2000,
          closeable: false,
        });
      }
    }
    removeAllOrdersLoadingRef.current = removeAllOrdersLoading;
  }, [removeAllOrdersLoading]);

  useEffect(() => {
    if (orderDetails) {
      D2.S.INFO('flyTo', { orderDetails, ordersDisplay });

      if (!orderDetails) return;

      const map = mapRef.current?.getMap();
      if (!map) return;

      if (ordersDisplay === 'dropoff_only') {
        map.flyTo({
          zoom: 16,
          center: [
            orderDetails.dropoff_location_lon,
            orderDetails.dropoff_location_lat,
          ],
          padding: PADDING,
        });
        return;
      }

      if (ordersDisplay === 'pickup_only') {
        map.flyTo({
          zoom: 16,
          center: [
            orderDetails.pickup_location_lon,
            orderDetails.pickup_location_lat,
          ],
          padding: PADDING,
        });
        return;
      }

      const geojson = {
        type: 'FeatureCollection',
        features: [orderDetails].reduce((memo, order) => {
          const properties = {};
          if (
            ordersDisplay === 'pickup_only' ||
            ordersDisplay === 'pickup_and_dropoff'
          ) {
            const coordinates = [
              order.pickup_location_lon,
              order.pickup_location_lat,
            ];
            memo.push({
              type: 'Feature',
              properties,
              geometry: {
                type: 'Point',
                coordinates,
              },
            });
          }
          if (
            ordersDisplay === 'dropoff_only' ||
            ordersDisplay === 'pickup_and_dropoff'
          ) {
            const coordinates = [
              order.dropoff_location_lon,
              order.dropoff_location_lat,
            ];
            memo.push({
              type: 'Feature',
              properties,
              geometry: {
                type: 'Point',
                coordinates,
              },
            });
          }
          return memo;
        }, []),
      };

      const viewport = getGeoViewportFromGeometry(geojson);

      const newViewport = {
        ...viewport,
        padding: PADDING,
      };

      D2.S.INFO('flyTo', newViewport);
      map.flyTo(newViewport);
    }
  }, [orderDetails, ordersDisplay]);

  const ordersGeoJSON = useMemo(() => {
    const displayMode = ordersDisplay;

    const geojson = {
      type: 'FeatureCollection',
      features: validOrders?.reduce((memo, order) => {
        const properties = {
          id: order.data?.external_id,
          assigned: !!(order?.$assignedVehicle && order?.assigned_vehicle_id),
        };
        if (
          displayMode === 'pickup_only' ||
          displayMode === 'pickup_and_dropoff'
        ) {
          const coordinates = [
            order.pickup_location_lon,
            order.pickup_location_lat,
          ];
          memo.push({
            type: 'Feature',
            properties,
            geometry: {
              type: 'Point',
              coordinates,
            },
          });
        }
        if (
          displayMode === 'dropoff_only' ||
          displayMode === 'pickup_and_dropoff'
        ) {
          const coordinates = [
            order.dropoff_location_lon,
            order.dropoff_location_lat,
          ];
          memo.push({
            type: 'Feature',
            properties,
            geometry: {
              type: 'Point',
              coordinates,
            },
          });
        }
        return memo;
      }, []),
    };
    D2.S.INFO('ordersGeoJSON', { geojson, displayMode });
    return geojson;
  }, [validOrders, ordersDisplay]);

  const [generatingRoute, setGeneratingRoute] = useState(false);
  const canGenerateRoute = useMemo(() => {
    if (!unfilteredOrders?.length) {
      return false;
    }
    // If all orders have dropoff and pickup coordinates
    const allContainCoords = unfilteredOrders.every(
      order =>
        order.dropoff_location_lat &&
        order.dropoff_location_lon &&
        order.pickup_location_lat &&
        order.pickup_location_lon
    );
    return allContainCoords;
  }, [unfilteredOrders]);

  useEffect(() => {
    if (
      !canGenerateRoute &&
      unfilteredOrders.length &&
      isAddressVerificationAvailableAfterImport
    ) {
      triggerAddressVerificationWarning();
    }
  }, [unfilteredOrders]);

  const [cursorStyle, setCursorStyle] = useState(null);

  // useEffect(() => {
  //   const map = mapRef.current.getMap();
  //   if (validOrders?.length) {
  //     const bounds = validOrders.reduce(
  //       (bounds, order) =>
  //         bounds.extend([
  //           order.dropoff_location_lon,
  //           order.dropoff_location_lat,
  //         ]),
  //       new mapboxgl.LngLatBounds()
  //     );
  //     map.fitBounds(bounds, { padding: PADDING });
  //   }
  //   setOrderDetails(false);
  // }, [validOrders]);

  const hasCompletedPickupNode = vehicles.some(vehicle =>
    vehicle.route.some(
      node => node.node_type === 'pickup' && node.status === 'completed'
    )
  );

  const currentProjectId = currentProject ? currentProject.get('id') : 0;
  const projectId =
    data && data.id
      ? (() => {
          const re = /\/.*\/([0-9]*)$/;
          return parseInt(data.project.match(re)[1], 10);
        })()
      : currentProjectId;

  const projectInfo =
    projectId &&
    projects &&
    Immutable.isImmutable(projects) &&
    projects.find(item => item.get('id') === projectId);

  const projectName = projectInfo ? projectInfo.get('name') : undefined;

  const [importingOrders, setImportingOrders] = useState(false);
  const [showImportErrors, setShowImportErrors] = useState(false);
  const [importErrors, setImportErrors] = useState({});

  const addNewBrowserHistory = () => {
    history.push(
      window.location.pathname + window.location.search + '&page=verification'
    );
  };

  const triggerAddressVerificationWarning = () => {
    setIsAddressVerificationAvailableAfterImport(false);
    global.openWarningMessage({
      title: t('p.Orders.addressVerification.dialog.title'),
      message: t('p.Orders.addressVerification.dialog.message'),
      buttons: [
        {
          text: t('p.Orders.addressVerification.dialog.btn.check'),
          action: () => {
            addNewBrowserHistory();
            global.openFullScreen({
              modalContent: <AddressVerification />,
            });
            global.closeWarningMessage();
          },
          fill: true,
        },
      ],
    });
  };

  const [orderIDs, setOrderIDs] = useState(new Set());
  const [resultBookingIds, setResultBookingIds] = useState([]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles?.length) {
      return;
    }

    setImportingOrders(true);
    const errorHandler = (importErrors) => {
      setShowImportErrors(true);
      setImportErrors(importErrors);
      setImportingOrders(false);
      setIsAddressVerificationAvailableAfterImport(true);
    };
    const successHandler = (result) => {
      commuteOfferRequestUpdate();
      setResultBookingIds(result.response.booking_ids);
    };
    const importColumnMap = projectInfo?.get('data')?.get('import_column_map');
    const existExternalIdList = orders.map(order => order.data.external_id);
    csvUpload(
      acceptedFiles,
      importColumnMap,
      simulation,
      updateSimulation,
      data,
      currentProjectConfig,
      timezone,
      errorHandler,
      successHandler,
      existExternalIdList
    );
  };

  useEffect(() => {
    setOrderIDs(
      new Set(
        orders.map((order) => {
          return order.id;
        })
      )
    );

    if (orders.length === 0) {
      finishInvalidateAllBookings();
    }
  }, [orders]);

  useEffect(() => {
    let interval;
    if (resultBookingIds.length > 0) {
      interval = setInterval(() => {
        let isFound = true;
        for (let i = 0; i < resultBookingIds.length; i++) {
          let bookingId = resultBookingIds[i];
          if (!orderIDs.has(bookingId)) {
            isFound = false;
            break;
          }
        }

        if (isFound) {
          toaster.info(
            <>
              {t('p.Orders.importSuccess', {
                count: resultBookingIds?.length || '.',
              })}
            </>,
            {
              autoHideDuration: 2000,
              closeable: false,
            }
          );
          setImportingOrders(false);
          setIsAddressVerificationAvailableAfterImport(true);
          clearInterval(interval);
          setResultBookingIds([]);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resultBookingIds, orderIDs]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    open,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/csv': [],
      'text/plain': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    disabled: !finishOfferLoading || isReadOnly,
  });

  const totalOrdersCount = unfilteredOrders?.length ?? 0;
  const ordersCount = orders?.length ?? 0;
  const onDropOutside = (e) => {
    e.preventDefault();
  };
  const hasUnassignedOrders = orders.find(
    order => !order?.$assignedVehicle || !order?.assigned_vehicle_id
  );

  const onRemoveBooking = ({ commuteOffer, uid, id }) => {
    try {
      if (!isReadOnly) {
        removeOrder({ commuteOffer, uid, id });

        toaster.info(
          <>
            {t('p.booking.card.modal.remove.order.OrderID.msg', {
              orderId: id,
            })}
          </>,
          {
            autoHideDuration: 4000,
            closeable: false,
          }
        );
      } else {
        toasterNegative(t('p.booking.card.modal.remove.booking.read.only'));
      }
    } catch (e) {
      toasterNegative(
        t('p.booking.card.modal.remove.booking.remove.OrderId.failed', {
          orderId: id,
        })
      );
    }
  };

  const removeBooking = (props) => {
    D2.S.FUNCTION('onRemoveBooking', { props }, () => {
      global.openWarningMessage({
        title: t('p.booking.card.modal.remove'),
        message: t('p.Orders.remove.modal.message', {
          orderId: props.orderId,
        }),
        buttons: [
          {
            text: t('c.messages.ea4788705e6873b424c65e91c2846b19'),
            action: () => {
              global.closeWarningMessage();
            },
            fill: false,
          },
          {
            text: t('p.booking.card.modal.remove'),
            action: () => {
              onRemoveBooking({
                commuteOffer: { ...data },
                uid: props.uid,
                id: props?.orderId,
              });

              setOrderDetails(false);
              global.closeWarningMessage();
            },
            fill: true,
          },
        ],
      });
    });
  };

  useEffect(() => {
    // If the dropzone is disabled, drag a file to upload will trigger the the default browser behavior, which is downloading the dropped file
    window.addEventListener('dragover', onDropOutside, false);
    window.addEventListener('drop', onDropOutside, false);
    return () => {
      window.removeEventListener('dragover', onDropOutside);
      window.removeEventListener('drop', onDropOutside);

      if (backListener !== undefined) {
        unregisterBackListener('use effect component unmount');
      }
    };
  }, []);

  const simulationDate = moment(simulation?.start_time)
    .tz(timezone)
    .format('DD/MM/YYYY');

  // global.openFullScreen({
  //   modalContent: <Edit />,
  // });

  return (
    <>
      <MapGL
        ref={mapRef}
        style={{ width: '100%', height: 'calc(100vh - 50px)' }}
        mapStyle={mapConfig.mapStyle}
        accessToken={mapConfig.token}
        boxZoom={false}
        logoPosition='bottom-right'
        onViewportChange={onViewportChangeHandler}
        cursorStyle={cursorStyle}
        viewportChangeMethod='flyTo'
        {...viewport}
      >
        <LanguageControl />
        <Source id='orders' type='geojson' data={ordersGeoJSON} />
        <Image id='pin-icon' image={assignedPinIcon} sdf />
        <Layer
          id='orders'
          type='symbol'
          source='orders'
          layout={{
            'icon-image': 'pin-icon',
            'icon-allow-overlap': true,
            'icon-offset': [-0.5, 0],
          }}
          paint={{
            'icon-color': [
              'case',
              ['boolean', ['get', 'assigned'], true],
              theme.colors.assignedOrderPin,
              theme.colors.unassignedOrderPin,
            ],
          }}
          onClick={(e) => {
            const feature = e.features[0];
            const { id } = feature.properties;
            const order = orders.find(o => o.data?.external_id === id);
            setOrderDetails(order);
          }}
          onHover={() => {
            setCursorStyle('pointer');
          }}
          onLeave={() => {
            setCursorStyle(null);
          }}
        />
        <Layer
          id='orders-labels'
          type='symbol'
          source='orders'
          paint={{
            'text-color': '#fff',
            'text-halo-width': 1.5,
            'text-halo-color': 'rgba(0,0,0,0.5)',
          }}
          layout={{
            'text-field': '{id}',
            'text-size': 10,
            'text-variable-anchor': ['left', 'right'],
            'text-radial-offset': 0.5,
          }}
          onClick={(e) => {
            const feature = e.features[0];
            const { id } = feature.properties;
            const order = orders.find(o => o.data?.external_id === id);
            setOrderDetails(order);
          }}
          onHover={() => {
            setCursorStyle('pointer');
          }}
          onLeave={() => {
            setCursorStyle(null);
          }}
        />
      </MapGL>
      <Container>
        <Panel>
          <Heading data-testid='Order-Count'>
            {t('p.Orders.panel.heading', {
              count: ordersCount,
            })}
          </Heading>
          <Toolbar>
            <InputWrapper>
              <img src={searchIcon} />
              <FilterInput
                placeholder={t('p.Orders.search.placeholder')}
                onChange={(e) => {
                  changeBookingsFilter(e.target.value.toLowerCase());
                }}
                placeholderColor={theme.colors.placeholder}
              />
            </InputWrapper>
          </Toolbar>
          <Dropzone {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive && isDragAccept && (
              <DropzoneDragActive>
                <p>{t('p.Orders.import.dropHere')}</p>
              </DropzoneDragActive>
            )}
            {isDragActive && isDragReject && (
              <DropzoneDragError>
                <p>{t('p.Orders.import.dropHereReject')}</p>
              </DropzoneDragError>
            )}
            <OrdersList>
              {orders.map(order => (
                <OrderItem
                  key={order.id}
                  onClick={(e) => {
                    setOrderDetails(order);
                  }}
                  selected={order.id === orderDetails?.id}
                >
                  <StatefulPopover
                    placement={PLACEMENT.bottom}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    content={({ close }) => {
                      const isAssigned = !!order?.assigned_vehicle_id;
                      return (
                        <StatefulMenu
                          items={[
                            {
                              label: t('p.Orders.order.menu.details'),
                              value: 'details',
                            },
                            {
                              label: t('p.booking.card.modal.remove'),
                              value: 'remove',
                              disabled: isAssigned,
                            },
                          ]}
                          onItemSelect={({ item }) => {
                            if (item?.value === 'details') {
                              setOrderDetails(order);
                            } else if (item?.value === 'remove') {
                              removeBooking({
                                orderId: order?.data?.external_id || order.id,
                                uid: order.uid,
                              });
                            }
                            close();
                          }}
                        />
                      );
                    }}
                  >
                    <OverflowButton
                      style={{
                        float: 'right',
                        margin: '-8px -8px 0 0',
                        color: '#97A0C0',
                      }}
                    >
                      <Overflow
                        overrides={{
                          Svg: {
                            style: { transform: 'rotate(90deg)' },
                          },
                        }}
                      />
                    </OverflowButton>
                  </StatefulPopover>
                  <OrderItemTitle>
                    <InsignificantText>
                      {t('p.Orders.order.id')}
                    </InsignificantText>{' '}
                    {order.data?.external_id || '-'}
                    <img
                      style={{ marginLeft: '9px' }}
                      src={
                        !order?.$assignedVehicle || !order?.assigned_vehicle_id
                          ? unassignedPinIcon
                          : assignedPinIcon
                      }
                    />
                  </OrderItemTitle>
                  {(ordersDisplay === 'pickup_and_dropoff' ||
                    ordersDisplay === 'pickup_only') && (
                    <div style={{ marginTop: 8 }}>
                      <InsignificantText>
                        {t('p.Orders.order.pickupAddress')}
                      </InsignificantText>
                      <br />
                      {getGeoCodedLocationName({
                        type: 'pickup',
                        addressData: order,
                      })}
                    </div>
                  )}
                  {(ordersDisplay === 'pickup_and_dropoff' ||
                    ordersDisplay === 'dropoff_only') && (
                    <div style={{ marginTop: 8 }}>
                      <InsignificantText>
                        {t('p.Orders.order.dropoffAddress')}
                      </InsignificantText>
                      <br />
                      {getGeoCodedLocationName({
                        type: 'dropoff',
                        addressData: order,
                      })}
                    </div>
                  )}
                </OrderItem>
              ))}
            </OrdersList>
          </Dropzone>
          <Footer>
            <Button
              type='button'
              disabled={totalOrdersCount === 0 || !hasUnassignedOrders}
              onClick={async (e) => {
                if (!canGenerateRoute) {
                  triggerAddressVerificationWarning();
                  return;
                }

                try {
                  global.openMethodWindow({
                    vehicleStats,
                    manualAction: () => {
                      history.push(
                        pageAddress({
                          page: LOGISTICS_ROUTE + '/routes',
                          serviceDate: moment(simulation.start_time)
                            .tz(timezone)
                            .format('YYYY-MM-DD'),
                          ...D2.CONTEXT,
                        })
                      );
                    },
                    autoAction: async () => {
                      setGeneratingRoute(true);

                      const vehicles_filter_expression =
                        simulation?.data?.logistics_api_settings
                          ?.vehicles_filter_expression;

                      const result = await optimizeLogisticsOrders(
                        simulation.id,
                        orders.map(o => o.uid),
                        { originalOffer: data, vehicles_filter_expression }
                      );

                      await waitSimulationProcessor(
                        result.response?.processor_id
                      );
                      await waitSimulationUncalculatedBookings(simulation.id);
                      const bookingsInfo = await getBookingsForSimulation(
                        simulation.id
                      );
                      await sleep(5000);

                      // Redirect to Routes URL
                      history.push(
                        pageAddress({
                          page: LOGISTICS_ROUTE + '/routes',
                          serviceDate: moment(simulation.start_time)
                            .tz(timezone)
                            .format('YYYY-MM-DD'),
                          ...D2.CONTEXT,
                        }),
                        {
                          fromAutoAssign: true,
                          bookingsInfo,
                        }
                      );
                    },
                  });
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e);
                } finally {
                  setGeneratingRoute(false);
                }
              }}
            >
              {t('p.Orders.footer.generateRoutes')}
            </Button>
            <StatefulPopover
              overrides={{
                Body: {
                  props: {
                    'data-testid': 'StatefulPopover-Body',
                  },
                },
                Arrow: {
                  props: {
                    'data-testid': 'StatefulPopover-Arrow',
                  },
                },
                Inner: {
                  props: {
                    'data-testid': 'StatefulPopover-Inner',
                  },
                },
              }}
              // placement={PLACEMENT.bottomLeft}
              content={({ close }) => (
                <StatefulMenu
                  items={[
                    {
                      label: (
                        <LabelSmall data-testid='Address verification'>
                          {t('p.Orders.footer.menu.addressVerification', {
                            numberOfRecords: numberOfUnResolvedAddresses,
                          })}
                        </LabelSmall>
                      ),
                      value: 'address-verification',
                      disabled: totalOrdersCount === 0,
                    },
                    {
                      label: (
                        <LabelSmall
                          data-testid='Import CSV'
                          data-debug={`finishOfferLoading:${finishOfferLoading}-isReadOnly:${isReadOnly}`}
                        >
                          {t('p.Orders.footer.menu.importCSV')}
                        </LabelSmall>
                      ),
                      value: 'import-csv',
                      disabled: !finishOfferLoading || isReadOnly,
                    },
                    {
                      label: (
                        <LabelSmall data-testid='Export CSV'>
                          {t('p.Orders.footer.menu.exportCSV')}
                        </LabelSmall>
                      ),
                      value: 'export-csv',
                      disabled: totalOrdersCount === 0,
                    },
                    {
                      label: (
                        <LabelSmall data-testid='Remove all orders'>
                          {t('p.Orders.footer.menu.removeAllOrders')}
                        </LabelSmall>
                      ),
                      value: 'invalidate-all-bookings',
                      disabled:
                        totalOrdersCount === 0 ||
                        hasCompletedPickupNode ||
                        isReadOnly,
                    },
                  ]}
                  onItemSelect={({ item }) => {
                    const value = item?.value;
                    switch (value) {
                      case 'address-verification': {
                        addNewBrowserHistory();
                        global.openFullScreen({
                          modalContent: <AddressVerification />,
                        });
                        break;
                      }
                      case 'import-csv': {
                        open();
                        break;
                      }
                      case 'export-csv': {
                        const exportColumnMap = projectInfo
                          ?.get('data')
                          ?.get('export_column_map');

                        exportLogisticsOrders(data, simulation, {
                          t,
                          language: i18n.language,
                          projectName,
                          exportColumnMap,
                        });
                        break;
                      }
                      case 'invalidate-all-bookings': {
                        global.openWarningMessage({
                          title: t('p.Orders.footer.menu.removeAllOrders'),
                          message: t('p.Orders.removeAllOrders.dialog.message'),
                          buttons: [
                            {
                              text: t('c.messages.Cancel'),
                              fill: false,
                            },
                            {
                              text: t('p.Orders.footer.menu.removeAllOrders'),
                              action: () => {
                                invalidateAllBookings(data);
                                global.closeWarningMessage();
                                global.closeFullScreen();
                              },
                              fill: true,
                              'data-testid':
                                'OrdersPanel-modal-removeAllOrders',
                            },
                          ],
                        });
                        break;
                      }
                    }
                    close();
                  }}
                  overrides={{
                    List: {
                      style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.menuBackground,
                      }),
                    },
                    Option: {
                      props: {
                        overrides: {
                          ListItem: {
                            style: {
                              paddingBottom: '16px',
                              paddingRight: '16px',
                              paddingLeft: '16px',
                              paddingTop: '16px',
                            },
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            >
              <OverflowButton data-testid='Order-context-menu'>
                <Overflow
                  overrides={{
                    Svg: {
                      style: { transform: 'rotate(90deg)' },
                    },
                  }}
                />
              </OverflowButton>
            </StatefulPopover>
            <div
              className={css({
                position: 'relative',
                top: '-6px',
                left: '-7px',
              })}
            >
              {numberOfUnResolvedAddresses > 0 && (
                <Badge> {numberOfUnResolvedAddresses}</Badge>
              )}
            </div>
          </Footer>
        </Panel>
        <Panel hidden={!orderDetails} style={{ marginLeft: 8 }}>
          {!!orderDetails && (
            <>
              <Heading
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {orderDetails.data?.external_id || '-'}
                <Delete
                  size={24}
                  style={{ cursor: 'pointer' }}
                  color='#97A0C0'
                  onClick={() => {
                    setOrderDetails(false);
                  }}
                />
              </Heading>
              <OrderDetails orderDetails={orderDetails} />
            </>
          )}
        </Panel>
      </Container>
      {(generatingRoute || importingOrders || removeAllOrdersLoading) && (
        <LoadingWithSpinner
          message={
            (generatingRoute && t('p.Orders.generatingRoutes')) ||
            (importingOrders && t('p.Orders.importingOrders')) ||
            (removeAllOrdersLoading &&
              t('p.Orders.footer.menu.removeAllOrders'))
          }
        />
      )}
      <Modal
        isOpen={showImportErrors}
        onClose={({ closeSource }) => {
          if (closeSource !== 'backdrop') {
            setShowImportErrors(false);
            setImportErrors({});
          }
        }}
        overrides={{
          Dialog: {
            style: {
              backgroundColor: '#080D14',
              marginTop: '60px',
              marginBottom: '60px',
            },
          },
        }}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat',
            fontSize: '24px',
            fontWeight: 700,
            marginTop: '10px !important',
          })}
        >
          {t('p.Orders.importError.heading')}
        </ModalHeader>
        <ModalBody
          className={css({
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 500,
            color: '#fff',
          })}
        >
          <Notification
            kind={KIND.negative}
            overrides={{
              Body: {
                style: {
                  width: 'auto',
                  backgroundColor: '#D91E3626',
                  border: '1px solid #D91E3666',
                  borderRadius: '4px',
                },
              },
              InnerContainer: { style: { color: '#fff' } },
            }}
          >
            <Alert
              size={16}
              overrides={{
                Svg: {
                  style: {
                    verticalAlign: 'middle',
                  },
                },
              }}
            />{' '}
            {t('p.Orders.importError.error')}
          </Notification>
          <p>{t('p.Orders.importError.invalidData')}</p>
          <ul>{getImportErrorList(importErrors, t)}</ul>
          <p>{t('p.Orders.importError.uploadAgain')}</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrdersPanel;
